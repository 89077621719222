import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import PlanSubscriptionUpdateCard from './PlanSubscriptionUpdateCard.vue'
import PlanConfirmSubscriptionUpdateCard from './PlanConfirmSubscriptionUpdateCard.vue'
import SubscriptionUpdateCardNeeded from './SubscriptionUpdateCardNeeded.vue'
import { Payment } from '@/models/payments/Payment'

export default {
    name: 'PlanSubscriptionUpdateCardNeeded',
    components: {
        HeaderTopDashboard,
        SubscriptionUpdateCardNeeded,
        PlanSubscriptionUpdateCard,
        PlanConfirmSubscriptionUpdateCard,
    },
    data () {
        return {
            payment: new Payment(),
            userPlanId: 0,
            userSubscriptionId: 0,
            planType: null,
        }
    },
    computed: {
    },
    methods: {
    },
    created () {
    },
    mounted () {
        this.userPlanId = this.$route.params.userPlanId
        this.userSubscriptionId = this.$route.params.userSubscriptionId
        this.planType = this.$route.params.planType
    },
}
